'use strict';

import _ from "underscore";

angular.module('tdl.services').factory('Menu', ['$resource', 'Conf', 'TDLAuth', '$rootScope', '$q', 'Team', 'Athlete',
function ($resource, Conf, TDLAuth, $rootScope, $q, Team, Athlete) {

    var serviceObj = {
      load: load,
      unload: unload,
      selectTeam: selectTeam,
      selectAthlete: selectAthlete,
      userAthlete: userAthlete,
      athletesInvolved:athletesInvolved,
      teamScope: teamScope,
      athleteScope: athleteScope,
      isActiveTeam: isActiveTeam,
      isActiveAthlete: isActiveAthlete,
      isReadOnlyTeam: isReadOnlyTeam,
      getStyle: getStyle,
      // Properties
      onChange: null,
      section: 'planning',
      style: null,
      multipleMode: false,
      teamsOrAthletes: 'teams',
      team: null,
      athlete: null,
	    form: null,
      athleteOnly: true,
      teams: [],
      athletes: [],
      visibleTeams: [],
      visibleAthletes: [],
      selectedTeams: [],
      selectedAthletes: [],
      loaded: false,
      isAdmin: false
    };

    return serviceObj;


    function load() {
      var deferred = $q.defer();

      if (TDLAuth.isLoggedIn() && !serviceObj.loaded) {

        serviceObj.isAdmin = TDLAuth.isAdmin();

        resetCollections();
        serviceObj.loaded = true;


        var teamPromise = $q.defer();

        Team.query().$promise.then(
          //success
          function (value) {
            var teams = _.sortBy(value,function(t) {
              var prefix = "1-";
              if(t.writeable)
                prefix = "0-"
              return prefix + t.name;
            });
            serviceObj.visibleTeams = angular.copy(teams);
            teamPromise.resolve(teams);
            serviceObj.teams = teams;
          },
          //error
          function (error) {
            teamPromise.reject('error loading team');
            console.log(error)
          }
        )

        var athletePromise = Athlete.query().$promise.then(
          //success
          function (value) {
            serviceObj.athletes = value;
            serviceObj.visibleAthletes = angular.copy(serviceObj.athletes);
            return serviceObj.athletes;
          },
          //error
          function (error) {
            console.log(error)

          }
        );

        $q.all([teamPromise.promise, athletePromise]).then(function (results) {
          const atl = userAthlete(TDLAuth.user);
          console.log(TDLAuth.user)
     
          deferred.resolve({
            teams: results[0],
            athletes: results[1]
          });
          
          setTimeout(() => {
            if(atl) {
              selectAthlete(atl._id)
            } else {
              if (TDLAuth.user.defaultTeam) {
                selectTeam(TDLAuth.user.defaultTeam)
              } else { 
                selectTeam(results[0][0]._id)
              }
            }
          },0)
          
        }, function (errors) {
          deferred.reject('Can\'t fetch teams and athletes');
        });

      } else {
        deferred.resolve({
          teams: serviceObj.teams,
          athletes: serviceObj.athletes
        });
      };

      return deferred.promise;
    }

    function unload() {
      resetCollections();
      serviceObj.loaded = false;
    }

    function userAthlete(user) {
      return serviceObj.athletes.find(x => x.userId === user._id)
    }

    function selectTeam(id,onsuccess) {
      serviceObj.teamsOrAthletes = 'teams'
      if (!serviceObj.multipleMode) {

        return Team.get({
          teamId: id
        }).$promise.then(
          //success
          function (value) {
            serviceObj.team = value;
            if(onsuccess) onsuccess();

            if(serviceObj.onChange) serviceObj.onChange()

          },
          //error
          function (error) {
            console.log(error);
          }
        )
      } else {
        serviceObj.team = {
          name: "Multiple Teams",
          _id: "all"
        };

        var index = serviceObj.selectedTeams.indexOf(id);

        if (index === -1) {
          serviceObj.selectedTeams.push(id);
        } else {
          serviceObj.selectedTeams.splice(index, 1);
        }

        if(serviceObj.onChange) {
          serviceObj.onChange()
        }

        return Promise.resolve(true);
      }

    }

    function selectAthlete(id,onsuccess) {
      serviceObj.teamsOrAthletes = 'athletes'
      if (!serviceObj.multipleMode) {

        return Athlete.get({
          athleteId: id
        }).$promise.then(
          //success
          function (value) {
            serviceObj.athlete = value;
            if(onsuccess) onsuccess();
            if(serviceObj.onChange) serviceObj.onChange()
          },
          //error
          function (error) {
            console.log(error);
          }
        )
      } else {
        serviceObj.athlete = {
          _id: "all",
          name: "Multiple Athletes",
          firstName: "Multiple",
          lastName: "Athletes"
        };

        var index = serviceObj.selectedAthletes.indexOf(id);

        if (index === -1) {
          serviceObj.selectedAthletes.push(id);
        } else {
          serviceObj.selectedAthletes.splice(index, 1);
        }
        if(serviceObj.onChange) serviceObj.onChange()

        return Promise.resolve(true);

      }
    }

    function isActiveAthlete(id) {
      var active = false;
      if (serviceObj.multipleMode) {
        var active = serviceObj.selectedAthletes.indexOf(id) > -1;
      } else if (serviceObj.athlete) {
        var active = serviceObj.athlete._id == id;
      }

      return active;
    }

    function isActiveTeam(id) {
      var active = false;


      if (serviceObj.multipleMode) {
        var active = serviceObj.selectedTeams.indexOf(id) > -1;
      } else if (serviceObj.team) {
        var active = serviceObj.team._id == id;
      }

      return active;
    }


    function isReadOnlyTeam(team) {
      return team && _.contains(TDLAuth.user.readOnlyTeams, team._id);
    }

    function resetCollections() {
      serviceObj.team = null;
      serviceObj.athlete = null;

      ['teams', 'athletes', 'selectedTeams', 'selectedAthletes'].forEach(function (property) {
        serviceObj[property].length = 0;
      });
    }

    function teamScope() {
      return serviceObj.teamsOrAthletes === 'teams';
    }

    function athleteScope() {
      return serviceObj.teamsOrAthletes === 'athletes';
    }

    function getStyle() {
      if(serviceObj.section == 'welcome' && serviceObj.style) {
        return serviceObj.style;
      }
    }

    function athletesInvolved() {
      if(serviceObj.athleteScope() && serviceObj.multipleMode) {
        return _.map(serviceObj.selectedAthletes, function(aId) {
          return _.find(serviceObj.athletes,function (a) {
            return a._id == aId;
          });
        });
      } else if(serviceObj.athleteScope() && !serviceObj.multipleMode) {
        return [serviceObj.athlete]
      } else if(serviceObj.teamScope() && serviceObj.multipleMode) {
        return _.flatten(_.map(serviceObj.selectedTeams,function(t) {
            return _.map(t.athletesIds, function(aId) {
              return _.find(serviceObj.athletes,function (a) {
                return a._id == aId;
              });
            });
          })
        )
      } else if(serviceObj.teamScope() && !serviceObj.multipleMode) {
        return _.map(serviceObj.team.athletesIds, function(aId) {
          return _.find(serviceObj.athletes,function (a) {
            return a._id == aId;
          });
        });
      } else {
        return [];
      }
    }

}]);
